import React from 'react'
import PropTypes from 'prop-types'

/**
 * Single card which can be open or closed depeands of passed state
 *
 * @param {object} faq  informations about cards { title, content }
 * @param {number} index position current card in array
 * @param {number} cardIndex represent state which contains info about clicked card position in array from one gruop 
 * @param {number} cardGroupIndex which group contains clicked card
 * @param {number} thisCardGroupIndex clicked card group
 * @param {func} setCardIndex  set state of FAQ info section component
 * @param {func} setCardGroupIndex state of FAQ info section component
 *
 * @returns {JSX Element}
 */
export default function FAQSingleCard({ faq, index, cardIndex, groupIndex, thisCardGroupIndex, setCardIndex, setCardGroupIndex, }) {
  return (
    <div className={`faq__infos-card faq__infos-card--${groupIndex === thisCardGroupIndex && index === cardIndex ? 'open' : 'closed'}`}>
      {/* eslint-disable-next-line */}
      <h6
        onClick={() => {
          setCardIndex(cardIndex === index && thisCardGroupIndex === groupIndex ? null : index)
          setCardGroupIndex(thisCardGroupIndex)
        }}
      >{faq.infoTitle}</h6>
      <div className="faq__infos-card-text" dangerouslySetInnerHTML={{ __html: faq.infoDesc }}></div>
    </div>
  )
}

FAQSingleCard.propTypes = {
  faq: PropTypes.object,
  index: PropTypes.number,
  cardIndex: PropTypes.number,
  groupIndex: PropTypes.number,
  thisCardGroupIndex: PropTypes.number,
  setCardIndex: PropTypes.func,
  setCardGroupIndex: PropTypes.func
}