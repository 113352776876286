import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Header from '../components/Header'
import Footer from '../components/Footer'
import FAQLayout from '../components/FAQLayout'
import Seo from '../components/Seo'
import CookiePopUp from '../components/CookiePopUp'

/*
 * Template for faq page 
 *
 * @param {object} data
 * @param {object} pageContext
 *
 * @returns {JSX Element}
 */
export default function faqTemplate({ data, pageContext }) {
  const { wpPage: { translations, language,
    faq_acf: { title, subtitle, cantFindTitle, cantSubtitle, cantFindUrl, groups } },
    allWpMenu,
    allWp: { nodes: [optionsPage] } } = data


  return (
    <>
      <Seo title={title} lang={language.locale} />
      <Header menuData={allWpMenu} language={language} translations={translations} />
      <div className="faq-template">
        <FAQLayout title={title} subtitle={subtitle} cantFindTitle={cantFindTitle} cantSubtitle={cantSubtitle} cantFindUrl={cantFindUrl} groups={groups} sideBannersData={optionsPage} />
      </div>
      <CookiePopUp language={language} />
      <Footer menuData={allWpMenu} language={language} footerOptionsPage={optionsPage} />
    </>
  )
}

faqTemplate.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object
}

export const faqDataQuery = graphql`
  query faqPageByID($id: String!) {
    wpPage(id: {eq: $id}) {
      translations {
        uri
      }
      language {
        locale
      }
      faq_acf {
        cantFindTitle
        cantSubtitle
        cantFindUrl {
          target
          title
          url
        }
        title
        subtitle
        groups {
          groupTitle
          group {
            informationsRepeater {
              fieldGroupName
              infoDesc
              infoTitle
            }
          }
        }
      }
    }
    allWpMenu {
      ...getMenus
    }
    allWp {
      nodes {
        ...getOptionsPageFooterData
        ...getOptionsPageSideBannerData
      }
    }
  }
`